
import { Options, Vue } from "vue-class-component";
import { Header } from "@/common/components";
import LoginForm from "@/modules/login/components/LoginForm.vue";
import { namespace } from "vuex-class";
import router from "@/router";

const loginStore = namespace('LoginStore')

@Options({
  components: {
    Header,
    LoginForm
  }
})
export default class Login extends Vue {
  @loginStore.State
  private authenticated!: boolean

  @loginStore.Mutation
  private setAuthenticated!: (authenticated: boolean) => void

  login(): void {
    this.setAuthenticated(true);
    router.push('/');
  }
}
