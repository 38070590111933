
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import router from "@/router";
import TokenResponse from "@/services/api/models/TokenResponse";
import { map, mergeMap } from "rxjs/operators";
import { UserResponse } from "@/services/api/models/UserResponse";

const userStore = namespace("UserStore");
const loginStore = namespace("LoginStore");

@Options({})
export default class LoginForm extends Vue {
  loginData = {
    username: "28443",
    password: "testing!testing",
    remember: false,
  };

  @loginStore.Mutation
  private setTokenData!: (data: TokenResponse) => void;

  @userStore.Mutation
  private setUserData!: (data: UserResponse) => void;

  login(): void {
    this.$api.user
      .token(this.loginData.username, this.loginData.password)
      .pipe(
        map((token) => {
          this.setTokenData(token);
        }),
        mergeMap(this.$api.user.userData),
        map((user) => {
          this.setUserData(user.data);
        })
      )
      .subscribe(
        (value) => {
          router.push("/");
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
