<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl font-extrabold text-system-gray-2">
        Login zum EDIS Admin-Tool
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Bitte geben Sie Ihre Zugangsdaten ein.
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div
        class="bg-system-gray py-8 px-4 border-2 border-system-gray-border sm:rounded-lg sm:px-10"
      >
        <form class="space-y-6" method="post">
          <div>
            <div class="">
              <label for="email">
                <input
                  v-model.trim="loginData.username"
                  id="email"
                  name="email"
                  type="text"
                  autocomplete="email"
                  required=""
                  placeholder="Benutzername"
                  class="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-system-blue focus:border-system-blue sm:text-sm"
                />
              </label>
            </div>
          </div>

          <div>
            <div class="mt-1">
              <label for="password">
                <input
                  v-model="loginData.password"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  placeholder="Passwort"
                  class="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-system-blue focus:border-system-blue sm:text-sm"
                />
              </label>
            </div>
          </div>

          <div class="flex items-center justify-between" v-if="false">
            <div class="flex items-center">
              <input
                v-model="loginData.remember"
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="h-4 w-4 text-system-blue focus:system-blue border-gray-300 rounded"
              />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                Angemeldet bleiben?
              </label>
            </div>
          </div>

          <div>
            <button
              @click="login"
              type="button"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-system-blue hover:system-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:system-blue"
            >
              Anmelden
            </button>
          </div>
        </form>
      </div>

      <div class="text-sm mt-8">
        <a
          href="#"
          class="font-medium text-system-blue hover:text-system-dark-blue underline"
        >
          Passwort vergessen?
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import router from "@/router";
import TokenResponse from "@/services/api/models/TokenResponse";
import { map, mergeMap } from "rxjs/operators";
import { UserResponse } from "@/services/api/models/UserResponse";

const userStore = namespace("UserStore");
const loginStore = namespace("LoginStore");

@Options({})
export default class LoginForm extends Vue {
  loginData = {
    username: "28443",
    password: "testing!testing",
    remember: false,
  };

  @loginStore.Mutation
  private setTokenData!: (data: TokenResponse) => void;

  @userStore.Mutation
  private setUserData!: (data: UserResponse) => void;

  login(): void {
    this.$api.user
      .token(this.loginData.username, this.loginData.password)
      .pipe(
        map((token) => {
          this.setTokenData(token);
        }),
        mergeMap(this.$api.user.userData),
        map((user) => {
          this.setUserData(user.data);
        })
      )
      .subscribe(
        (value) => {
          router.push("/");
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
</script>

<style scoped></style>
